@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

.test-var-color {
  color: var(--color-main);
}

* {
  font-family: "Nunito", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  word-wrap: break-word;
  transition: all 0.3s ease 0s !important;
}

main, .main-screen, html {
  background-color: aliceblue;
}

.child-sm-box, .child-box, .update-form {
  background-color: #fff;
}

header.header {
  z-index: 1;
}

aside.sidebar {
  z-index: 2;
}

.modal-tooltip > .modal-dialog {
  z-index: 100 !important;
}

option, select {
  text-transform: capitalize !important;
}


a {
  text-decoration: none !important;
}


.header-cat {
  display: flex!important;
  min-width: 80%;
}

span.carousel-control-prev-icon, span.carousel-control-next-icon {
  background-color: #000000;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.carousel-control-next, .carousel-control-prev {
  width: 60px !important;
  height: 60px !important;
  background-color: #0078e9 !important;
  top: 82% !important;
  border-radius: 50% !important;
}

.carousel-control-next {
  right: 35%  !important;
}

.carousel-control-prev {
  left: 35%  !important;
}


/*carrusel*/
#cards,
#types {
  background: rgb(255,255,255);
  background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(222,225,255,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(222,225,255,1) 100%);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(222,225,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#dee1ff",GradientType=1);
}

#textCategories {
  color: #0078e9;
  text-transform: uppercase;
  font-weight: 900;
}

.img-ref img {
  width: 100%;
  padding: 15px;
  border-radius: 10%;
}

.nav-logo {
  color: #000;
  text-decoration: none;
  font-weight: bold;
}

.nav * {
  text-decoration: none;
}

/* tooltip modal */

.tooltip-question {
  text-decoration: none !important;
  border: 1px solid #0064C8;
  color: #0064C8;
  padding: 0px 6px;
  border-radius: 50%;
  font-weight: 900;
  font-size: 13px;
}

button.tooltip-btn {
  background-color: transparent !important;
  margin: -10px 0px 0px 0px !important;
  height: 20px;
}

.tooltip-btn {
  text-decoration: none !important;
  margin: 0px;
  padding: 0px 0px 5px 0px !important;
  color: #000 !important;
}

.modal-backdrop.show {
  opacity: 0 !important;
}


/* Titles */
.result-text {
  font-size: 18px;
  font-weight: 900;
}

.subtitle {
  color: #0064C8;
  font-weight: 600;
}

.big-result-text {
  font-weight: 900;
  font-size: 28px;
  padding-bottom: 15px;
  color: #202020;
}

.table-head {
  font-weight: 900;
  text-transform: uppercase;
  font-size: 13px;
}



.admin-logo {
  margin: 0px 5px 5px 5px;
  font-size: 15px;
  color: #fff;
}


.units-lowercase, .category-uppercase {
  text-transform: initial;
}


.category-uppercase {
  text-transform: capitalize;
}



td.col-options {
  display: flex;
}

svg.options-logo {
  margin: 0px 5px;
}


#highcharts-data-table-4 > thead > tr > th:nth-child(1), #highcharts-data-table-4 > caption {
  color: transparent;
}


/* footer */

.main-screen {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.welcome {
  flex: 1; /* El contenido principal ocupará todo el espacio restante */
}


.main-footer {
  position: fixed;
  display: flex;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  padding: 10px 0;
}

.upb-logo img {
  padding: 0px 10px 0px 0px;
  margin: 0px 0px -15px 0px;
  width: 60px;
}

a.submenu-item.active {
  background-color: #b4b4b4;
}

.fade {
  background-color: #00000059;
}

.shadow-left {
  -webkit-box-shadow: -4px 9px 13px -6px rgba(107,107,107,1);
  -moz-box-shadow: -4px 9px 13px -6px rgba(107,107,107,1);
  box-shadow: -4px 9px 13px -6px rgba(107,107,107,1);
}

.shadow-menu {
  -webkit-box-shadow: -11px 15px 24px -16px rgba(0,0,0,0.75);
  -moz-box-shadow: -11px 15px 24px -16px rgba(0,0,0,0.75);
  box-shadow: -11px 15px 24px -16px rgba(0,0,0,0.75);
}

.shadow-submenu {
  -webkit-box-shadow: 7px 0px 21px 1px rgba(143,143,143,1);
  -moz-box-shadow: 7px 0px 21px 1px rgba(143,143,143,1);
  box-shadow: 7px 0px 21px 1px rgba(143,143,143,1);  
}

.field-img {
  -webkit-box-shadow: -11px 15px 24px -16px rgba(0,0,0,0.75);
  -moz-box-shadow: -11px 15px 24px -16px rgba(0,0,0,0.75);
  box-shadow: -11px 15px 24px -16px rgba(0,0,0,0.75);
  border-radius: 10px;
  height: 165px;
}

.shadow-blue-right, .sidebar.show {
  -webkit-box-shadow: 7px 0px 61px -13px rgba(89,126,163,1);
  -moz-box-shadow: 7px 0px 61px -13px rgba(89,126,163,1);
  box-shadow: 7px 0px 61px -13px rgba(89,126,163,1);
}

.plus-container {
  display: flex;
  justify-content: center;
  align-items: center;    
  height: 60px;  
  width: 60px !important;       
  background-color: #0064C8;
  border-radius: 50%;
  transition: all 0.5s;  
}

.plus-container:hover {   
  height: 70px;  
  width: 70px !important;       
  background-color: #0085c8;
}

.plus-centered {
  width: 35px;         
  height: 35px;         
  display: flex;         
  justify-content: center; 
  align-items: center;  
  transition: all 0.5s;  
}

.plus-centered:hover {
  transform: rotate(360deg);
  height: 90px;
  width: 90px;
}

.card-country:hover {
  /* width: 19rem !important; */
  margin: 0px 10px;
}
