:root {
  --header-height: 3rem;
  --nav-width: 68px;
}

.submenu-item {
  display: block;
  padding: 0.5rem 2.5rem;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s;
  background-color: white;
}

.submenu-item:hover {
  color: white;
  background-color: black;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

main {
  position: relative;
  margin: var(--header-height) 0 0 0;
  padding: 0 1rem;
  font-size: 1rem;
  font-family: "Nunito Sans", sans-serif;
  transition: 0.5s;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  height: var(--header-height);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding: 0 1rem;
  transition: 0.5s;
  z-index: 1;
}

.header-toggle {
  font-size: 1.25rem;
  cursor: pointer;
  color: black;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -30%;
  height: 100vh;
  width: var(--nav-width);
  background-color: #f0f0f0;
  padding-top: 1rem;
  transition: 0.5s;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 0;
}

.sidebar::-webkit-scrollbar {
  width: 7px;
  border-radius: 5px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #b5b2b2;
  border-radius: 5px;
}

.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.nav::-webkit-scrollbar {
  width: 8px;
}

.nav::-webkit-scrollbar-track {
  background-color: var(--first-color-alt);
}

.nav::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 4px;
}

.nav::-webkit-scrollbar-thumb:hover {
  background-color: #333;
}

.nav-logo,
.nav-li,
.nav-li-modal {
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 2rem;
  padding: 0.5rem 0 0.5rem 1rem;
}

.nav-logo {
  margin-bottom: 2rem;
}

.nav-logo-icon {
  font-size: 1.25rem;
  cursor: pointer;
  margin-left: 7px;
}

.nav-logo-name {
  font-weight: 700;
  color: white;
}

.nav-li {
  position: relative;
  color: black;
  transition: 0.5s;
  margin-bottom: 1.5rem;
}

.nav-li:hover {
  color: white;
  background-color: #444444;
}

.nav-li-modal {
  position: relative;
  color: black;
  transition: 0.5s;
  height: 40px;
  margin-bottom: 20px;
}

.nav-li-modal:hover{
  background: #444444;
  color:white;
}

.activate {
  color: white;
  background-color: rgba(0, 0, 0, 0.1);
}

.activate::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 38px;
  width: 3px;
  background-color: white;
  transition: 0.5s;
}

.show {
  left: 0;
}

.space-toggle {
  padding-left: calc(var(--nav-width) + 1rem);
}

.submenu {
  z-index: 2;
  margin-top: -25px;
  margin-left: 10px;
  position: fixed;
  transition: all 0.2s ease 0s !important;
  border: solid 1px grey;
}

@media screen and (max-width: 767px) {
  .submenu.show {
    width: 40%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .submenu.show {
    width: 30%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 3500px) {
  .submenu.show {
    width: 15%;
  }
}

@media screen and (min-width: 768px) {
  main {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding: 0 1rem 0 calc(var(--nav-width) + 2rem);
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding-left: calc(var(--nav-width) + 2rem);
  }

  .sidebar {
    left: 0;
    padding: 1rem 0 0 0;
  }

  .show {
    width: calc(var(--header-height) + 170px);
  }

  .space-toggle {
    padding-left: calc(var(--nav-width) + 188px);
  }
}

.nav-li.active {
  background-color: #b4b4b4 !important;
}
