

a.nav-link.dropdown-toggle {
  text-transform: capitalize;
  font-weight: 700;
}

ul.dropdown-menu.show {
  margin-top: 15px !important;
  border: 1px solid #dfe8f5;
  border-radius: 0;
}

ul.dropdown-menu {
  border-radius: 10px !important;
  transform: translate(0px, 0px, 0px, 0px) !important;
  transition: all 0.2s ease 0s;
}

.nav-item.dropdown.active > a.nav-link.dropdown-toggle {
  color: #f8f8f8;
  border-bottom-color: #fafafa;
  text-transform: capitalize;
} 

.vehicle-list-container li {
  display: inline-block;
  margin-right: 10px;
}

li.nav-item.dropdown {
  padding: 0px 15px !important;
}

.nav-link {
  display: inline !important;
}