.container-PDF {
    width: 1480px;
    max-width: 100% !important;
    height: auto;
    padding: 70px;
}

.modal-results .modal-dialog.modal-lg {
    width: 1480px;
    max-width: 100% !important;
}

.modal-results .modal-content {
    width: 1480px !important;
    max-width: 100% !important;
}

.modal-pdf.modal-body {
    padding: 50px;
}

.bold-title {
    font-weight: bold;
    font-size: 18px;
}

.text {
    margin: 0px !important;
    font-size: 14px;
}

.downloader-header {
    display: flex;
}

.logo {
    margin-left: 55%;
}

#highcharts-data-table-4 > thead > tr > th:nth-child(1), #highcharts-data-table-4 > caption {
    color: transparent;
  }

  /* i.tooltip-question {
    display: none;
} */