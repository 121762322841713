.col-form-label {
  color: black;
}

p {
  margin: 0px;
}

.modal-backdrop {
  z-index: 1;
}

.modal-backdrop.show {
  opacity: 0;
}

.tooltip-custom {
  background-color: #efefef;
  width: 15px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 1px 1px 4px;
  font-size: 10px;
  font-weight: bold;
  margin-left: auto;
  position: relative;
  padding: 2px 5px;
  left: 5px;
}

.modal-dialog.modal-dialog-centered.modal-dialog-scrollable {
  position: relative;
  top: 45px;
}

.swal2-container {
  z-index: 9999999 !important;
}

button.swal2-confirm.my-confirm-button.swal2-styled {
  background-color: #0d6efd;
}


.modal-lavel {
  font-weight: 900;
  font-size: 18px;
  padding-top: 15px !important;
  min-width: 100%;
}

