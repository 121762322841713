.tree-sec {
    display: flex;
    flex-wrap: wrap;
}

.tree {
    max-width: 265px;
    height: auto;
    margin: 20px;
    flex: 1 1;
    min-width: 265px;
}

.tree img {
    width: 100%;
}

