
.mecanic-view {
    display: flex;
    flex-wrap: wrap;
  }
  
  .sidenav-aux {
    min-width: 30%;
    flex-grow: 1;
    margin-top: auto; 
  }
  
  .content {
    min-width: 70%;
    flex-grow: 1;
  }
  

.nav_link_content {
    display: block;
    padding: 15px 5px 15px 30px;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    color: #464646;
    border-bottom: 1px solid #dbdbdb;
    text-align: end;
}

.nav_link_content:hover {
  background-color: #f1f1f1;
  color: #464646;
}

@media screen and (max-width: 430px) {
    .content {
        min-width: 290px;
        flex: 1;
    }
}


.nav_link_content.active {
  background-color: #e4e4e4 !important;
}