* {
  font-family: "roboto";
}

.subtitles {
  font-size: 17px;
}

/*estilos de las tablas*/

table {
  text-align: center;
}

.custom-table {
  border-collapse: collapse;
}

.custom-table th:not(:first-child):not(:last-child),
.custom-table td:not(:first-child):not(:last-child) {
  border-left: none;
  border-right: none;
}

.custom-table th:first-child,
.custom-table td:first-child {
  border-right: none;
}

.custom-table th:last-child,
.custom-table td:last-child {
  border-left: none;
}

.custom-table th,
.custom-table td {
  text-align: left;
}

.custom-table1 td:first-child,
.custom-table1 th:first-child {
  background-color: #f1f1f1;
  border: none;
}

table.custom-table1 tr:last-child td {
  border-bottom: none;
  background: #f1f1f1;
}

/* .tables {
  width: 100%;
} */

/*botones*/

.boton {
  border: none;
  border-radius: 5px;
  text-align: center;
}

/*categorias*/
.categories{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#cards{
    width: 400px;
    height: 360px;
    border: none;
    transition: all 0.2s ease 0s;
}

#cards:hover{
    width: 410px;
    height: 375px;
    transition: all 0.3s ease 0s;
}

#types{
  transition: all 0.3s ease 0s;
}

#types:hover{
  width: 385px;
  transition: all 0.5s ease 0s;
}

#textCategories{
  font-size: 30px;
  text-decoration: none;
}

div#types a, div#cards a {
  text-decoration: none;
}


/*tipos*/
#types{
  width: 430px;
  height: auto;
  border: none;
  border-radius: 12px;
}

.vehicle-image  {
  max-width: 100%;
  max-height: 400px;
  margin: auto;
  padding: 10px 10px 30px 10px;
  mix-blend-mode: multiply;
}

.vehicle-image img {
width: 100%;
max-height: 400px;
border-radius: 4%;
}

/*responsive*/
@media (max-width:600px){
  #types{
    width: 400px;
    height: auto;
    border: none;
    border-radius: 12px;
  }
}

@media (min-width:1101px){
  #types{
    width: 370px;
    height: 400px;
    border: none;
    border-radius: 12px;
  }
}



/*responsive*/
@media (max-width: 700px) {
  .custom-table thead th {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }

  .custom-table th:nth-child(n) {
    display: none;
  }

  .custom-table,
  .custom-table tbody,
  .custom-table tr,
  .custom-table td,
  .custom-table th {
    display: block;
    width: 100%;
    border: none;
  }

  .custom-table {
    border: 1px solid gray;
  }

  .custom-table tr {
    margin-bottom: 15px;
  }

  .custom-table td {
    text-align: center;
    padding-left: 50%;
    text-align: right;
    position: relative;
  }

  .custom-table td:before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
  }

  .custom-table th:last-child,
  .custom-table td:last-child {
    border-bottom: 1px solid #f1f1f1;
  }
}

@media (max-width: 1540px) and (min-width: 1500px) {
  .custom-table,.custom-table1 {
    position: relative;
    right: 40px;
  }
}

@media (max-width: 1600px) and (min-width: 1541px) {
  .custom-table,.custom-table1 {
    position: relative;
    right: 50px;
  }

}

@media (max-width: 1700px) and (min-width: 1601px) {
  .custom-table {
    width: 110% !important;
    position: relative;
    right: 80px;
  }

  .custom-table1 {
    position: relative;
    right: 80px;
  }
}

@media (max-width: 1850px) and (min-width: 1701px) {
  .custom-table {
    width: 110% !important;
    position: relative;
    right: 120px;
  }

  .custom-table1 {
    position: relative;
    right: 120px;
  }
}

@media (max-width: 2000px) and (min-width: 1851px) {
  .custom-table {
    width: 120% !important;
    position: relative;
    right: 250px !important;
  }

  .custom-table1 {
    position: relative;
    right: 250px !important;
  }
}

@media (max-width: 2200px) and (min-width: 2001px) {
  .custom-table {
    width: 120% !important;
    position: relative;
    right: 300px;
  }

  .custom-table1 {
    position: relative;
    right: 300px;
  }
}

@media (max-width: 2300px) and (min-width: 2201px) {
  .custom-table {
    width: 155% !important;
    position: relative;
    right: 390px;
  }

  .custom-table1 {
    position: relative;
    right: 390px;
  }
}

@media (max-width: 2500px) and (min-width: 2301px) {
  .custom-table {
    width: 155% !important;
    position: relative;
    right: 400px;
  }

  .custom-table1 {
    position: relative;
    right: 400px;
  }
}

@media (max-width: 3000px) and (min-width: 2501px) {
  .custom-table {
    width: 170% !important;
    position: relative;
    right: 520px;
  }

  .custom-table1 {
    position: relative;
    right: 520px;
  }
}
