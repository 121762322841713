.selector-quoter {
    text-align: end;
    max-width: 33.5%;
    min-width: 250px;
    margin-left: auto;
}


select {
    word-wrap: normal;
    max-width: 33.5%;
    height: 35px;
    text-align: center;
    padding: 0px;
}