.update-scheme {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.update-form {
  border: 1px solid lightgray;
  padding: 20px;
  overflow: hidden;
}

.update-form h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

form {
  display: grid;
  grid-gap: 10px;
  margin-bottom: 20px;
}

label {
  display: grid;
  grid-template-columns: minmax(100px, 200px) 1fr;
  align-items: center;
}

input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
}

button {
  display: inline-block;
  padding: 8px 16px;
  font-weight: bold;
  color: #fff;
  background-color: #0064C8;
  border: none;
  border-radius: 5% !important;
}

p {
  margin-bottom: 20px;
}

select {
  min-width: 100%;
}


table, tbody, thead, tr, td, th {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px solid #ececec !important;
  padding: .35em;
}

